import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { SEO, GeneralJumbotron, SummaryTemplate, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { TranslationImagesQuery } from '../shared/interfaces';

const TranslationPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<TranslationImagesQuery>(graphql`
        query {
            translationBackgroundImage: file(name: {eq: "translationBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
            translationSummaryImage: file(name: {eq: "translationSummary"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Translation"/>
            <GeneralJumbotron
                title="Translation"
                backgroundImageURL={queryResult?.translationBackgroundImage.publicURL}
                backgroundText="Translation"
            />
            <SummaryTemplate image={queryResult?.translationSummaryImage.publicURL}>
                <p>
                    {`
                        At PCH IPA, we understand the need for high-quality interpreters.
                        That’s why we’ve contracted with a national network that is
                        staffed with reliable interpreters who can handle medical
                        appointments, depositions, and scheduled over-the-phone meetings
                        for your office. Whether you have an in-person or telephonic need,
                        we have a solution for you!
                    `}
                </p>
                <p>
                    {`
                        Do you need documents translated? We are proud to say that our
                        network consists of professional translators who are all native
                        speakers of the language requested and who are recognized experts
                        in their subject areas. Each translator is rigorously tested and
                        evaluated by the sourcing panel, so you can feel confident that
                        your message is being consistently conveyed to your
                        internal/external audiences.
                    `}
                </p>
                <p>
                    {`To learn more, call us at `}
                    <a href="tel:+18337244111">
                        <FontAwesomeIcon icon={faPhoneAlt}/>
                        {`1-833-PCH-4111`}
                    </a>
                </p>
            </SummaryTemplate>
            {/* <CallToAction/> */}
        </MainLayout>
    );
}

export default TranslationPage;
